import React from "react";
import Reveal from "react-reveal/Reveal";

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <Reveal effect="fadeInLeft">
                  <div className="image">
                    <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + "/img/about/1.jpg"
                        })`,
                      }}
                    ></div>
                  </div>
                </Reveal>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <Reveal effect="fadeInLeft">
                  <div className="shane_tm_title">
                    <span>About Us</span>
                    <h3>software continuous delivery</h3>
                  </div>
                  <div className="text">
                    <p>
                    MHHC is a professional software development company with headquarters in Sydney (Australia), 
With 15 year software development experience. We help our clients to achieve quality application development. 
                    </p>
                  </div>
                  {/* <div className="shane_tm_button">
                    <a href="img/resume/resume.jpg" download>
                      Download CV
                    </a>
                  </div> */}
                </Reveal>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
